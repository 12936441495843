<template>
  <b-card-actions
    ref="accounts"
    no-actions
    no-body
  >
    <!-- !! Table Header -->
    <div class="mx-1">
      <b-row>
        <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
      </b-row>
    </div>

    <!-- !! Table -->
    <b-row class="my-1">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          responsive
          bordered
          :sticky-header="true"
          :no-border-collapse="true"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          style="font-weight: normal; font-size: 12px;"
          @sort-changed="doTableSort"
        >
          <template #head(account_number)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(client_legal_name)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(sf_last_modified_date)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(salesforce_link_url)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(legal_entity)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #cell(name)="data">
            <router-link :to="`/accounts/${data.item.account_id}`">
              {{ data.item.name }}
            </router-link>
          </template>

          <template #cell(sf_last_modified_date)="data">
            {{ formatUsaDate(data.item.sf_last_modified_date) }}
          </template>

          <template #cell(salesforce_link_url)="data">
            <a :href="data.item.salesforce_link_url" target="_blank">
              <feather-icon icon="GlobeIcon" class="mr-50" />
            </a>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import DataFormatService from '@/services/DataFormatService'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import AccountFactory from '@/factories/Account'
import Constant from '@/utils/Constant'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  data() {
    return {
      showOverlay: true,
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Account Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Account Owner',
            key: 'account_owner',
            sortable: true,
          },
          {
            label: 'Account Number',
            key: 'account_number',
            sortable: true,
          },
          {
            label: 'Client Legal Name',
            key: 'client_legal_name',
            sortable: true,
          },
          {
            label: 'Industry',
            key: 'industry',
            sortable: true,
          },
          {
            label: 'Last Modified Date',
            key: 'sf_last_modified_date',
            sortable: false,
          },
          {
            label: 'Saleforce Link',
            key: 'salesforce_link_url',
            sortable: false,
          },
          {
            label: 'Legal Entity',
            key: 'legal_entity',
            sortable: true,
          },
        ],
      },
    }
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.accounts.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig
        const qparam = {
          perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection,
        }
        const response = await axiosInstance.get(Constant.apiURL.salesforceAccount, { params: qparam })
        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = AccountFactory.createFromJsonArray(response.data.data.data)
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.tableConfig.currentPage = 1
        this.tableConfig.totalRows = 0
        this.items = []
      } finally {
        if (this.$refs.accounts) {
          this.$refs.accounts.showLoading = false
        }
      }
    },

    columnDate(data) {
      if (data) {
        return moment(data).tz('Asia/Bangkok').format('MMMM DD, YYYY')
      }

      return null
    },
  },
}
</script>

<style>
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
  /* or:
  width: auto
  */
}
.b-table-sticky-header {
  max-height: calc(100vh - 310px);
}
</style>
